import { render, staticRenderFns } from "./flotRight.vue?vue&type=template&id=740e7e06&"
import script from "./flotRight.vue?vue&type=script&lang=js&"
export * from "./flotRight.vue?vue&type=script&lang=js&"
import style0 from "./flotRight.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports