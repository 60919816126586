<template>
  <div>
    <div class="hlCenter">
      <a rel="nofollow"
         target="_blank"
         href="https://www.sobot.com/chat/pc/index.html?sysNum=ab2e331f1c2d41c4878fa99631b6f703"
         style="display:block;"
         class="cc cc1">
        <span>在线咨询</span>
      </a>
      <div class="cc cc2">
        <span>电话咨询</span>
        <div class="ccc">
          <p>400-8816-822</p>
          <p>服务时间:8:30-17:30</p>
          <img src="../../assets/indexImg/icon_03_03.png" />
        </div>
      </div>
      <div class="cc cc4">
        <span>公众号</span>
        <div class="ccc1">
          <img src="../../assets/indexImg/icon_11.png"
               class="icon1" />
          <img src="../../assets/indexImg/icon_03_03.png"
               class="icon" />
        </div>
      </div>
      <router-link to="/xcx"
         target='_blank;'>
        <div class="cc cc5">
          <span>小程序</span>
          <div class="ccc1">
            <img style="width: 120px;height: 120px"
                 src="../../assets/indexImg/wetApp1.jpg"
                 class="icon1" />
          </div>
        </div>
      </router-link>
      <div class="cc cc7">
        	<span>手机端</span>
        	<div class="ccc1">
        		<img :src="ewm" class="icon1" style="margin-top: 22px;"/>
        		<img src="../../assets/indexImg/icon_03_03.png" class="icon"/>
        	</div>
        </div>
      <router-link to="/zscy"
         class="cc cc6"
         style="display:block;">
        <span>证书查验</span>
      </router-link>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { toAppEwm } from '@/api/index.js'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      ewm:''
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    toAppEwm().then(res => {
      this.ewm = res.data.data
    })
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style>
/* //@import url(); 引入公共css类 */
.hlCenter {
  z-index: 10000;
  position: fixed;
  top: 22%;
  right: 10px;
  width: 50px;
  height: auto;
}
.hlCenter div img{
	margin-top: 4px;
	margin-bottom: 2px;
}
.hlCenter div span{
	font-size: 12px;
    color: #3797e0;
    display: block;
    margin-top: 30px;
    cursor:default;
}
.hlCenter a span{
	font-size: 12px;
    color: #3797e0;
    display: block;
    margin-top: 30px;
}
.hlCenter a:hover span{
	color: #fff;
}
.hlCenter .cc{
	position: relative;
}
.hlCenter div:hover span{
	color: #fff;
}
.hlCenter .cc1{
	background: url('../../assets/indexImg/icon_03.png') no-repeat 15px 4px;
}
.hlCenter .cc2{
	background: url('../../assets/indexImg/icon_09.png') no-repeat 15px 4px;
}
.hlCenter .cc3{
	background: url('../../assets/indexImg/icon_14.png') no-repeat 15px 4px;
}
.hlCenter .cc4{
	background: url('../../assets/indexImg/icon_22.png') no-repeat 15px 4px;
}
.hlCenter .cc5{
	background: url('../../assets/indexImg/app_icon1.png') no-repeat 15px 4px;
}
.hlCenter .cc6{
	background: url('../../assets/indexImg/icon_35.png') no-repeat 15px 4px;
}
.hlCenter .cc7{
	background: url('../../assets/indexImg/phone_03.png') no-repeat 17px 4px;
}
.hlCenter .cc1:hover{
	background:#599dd7 url('../../assets/indexImg/icon_05.png') no-repeat 15px 4px;
}
.hlCenter .cc2:hover{
	background: #599dd7 url('../../assets/indexImg/icon_10.png') no-repeat 15px 4px;
}
.hlCenter .cc3:hover{
	background:#599dd7 url('../../assets/indexImg/icon_17.png') no-repeat 15px 4px;
}
.hlCenter .cc4:hover{
	background:#599dd7 url('../../assets/indexImg/icon_24.png') no-repeat 15px 4px;
}
.hlCenter .cc5:hover{
	background:#599dd7 url('../../assets/indexImg/app_icon2.png') no-repeat 15px 4px;
}
.hlCenter .cc7{
	background: url('../../assets/indexImg/phone_03.png') no-repeat 17px 4px;
}
.hlCenter .cc7:hover{
	background:#599dd7 url('../../assets/indexImg/phone_05.png') no-repeat 17px 4px;
}
.hlCenter .cc6:hover{
	background:#599dd7 url('../../assets/indexImg/icon_38.png') no-repeat 15px 4px;
}

.hlCenter div .ccc{
	width: 128px;
	height: 54px;
	position: absolute;
	left: -140px;
	top: 0;
	border: 1px solid #92cdff;
	border-radius: 4px;
	display: none;
	background:#fff;
}
.hlCenter div .ccc1{
	width: 128px;
	height: 128px;
	border: 1px solid #92cdff;
	border-radius: 5px;
	position: absolute;
	left: -140px;
	top: -34px;
	display: none;
	background:#fff;
}
.hlCenter div:hover .ccc{
	display: block;
}
.hlCenter div:hover .ccc1{
	display: block;
}
.hlCenter div .ccc1 .icon{
	position: absolute;
	left: 126px;
	top: 50px;
}
.hlCenter div .ccc1 .icon1{
	text-align: center;
	display: inline-block;
}
.hlCenter div .ccc p{
	color: #666;
	margin-top: 5px;
}
.hlCenter div .ccc img{
	position: absolute;
	left: 126px;
	top: 18px;
}
 body,html{
  -ms-overflow-style: scrollbar;
 }

.hlCenter .cc{
	width:54px;
	height: 54px;
	text-align: center;
	margin-top: 4px;
	border-radius: 5px;
	border: 1px solid #198ede;
}

.hlCenter .hlMain {
  background: #fff;
  overflow: hidden;
  width: 121px;
  margin-left: 10px;
  margin-top: 5px;
  padding: 5px 3px 0 3px;
  border: 2px solid #207CB2;
}
.hlCenter .hlMain .usQuest span {
  font-size: 15px;
  color: #3A81B7;
  font-weight: 600;
}
.hlCenter .hlMain .usQuest img {
  position: relative;
  top: -3px;
  margin-right: 5px;
}
.hlCenter .hlMain .usInstan {
  margin-top: 2px;
  float:left;
  border-top: 1px dashed #626262;
  border-bottom: 1px dashed #DAD4D0;
  padding: 5px 0 5px 0;
}
.hlCenter .hlMain .usInstan a {
  color: #626262;
}
.hlCenter .hlMain .usSug {
  margin-top: 3px;
}
.hlCenter .hlMain .usSug img {
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.hlCenter .hlMain .usSug .usSugg {
  width: 88px;
}
.hlCenter .hlMain .usSug .usSugg .usSug1 {
  font-size: 12px;
  color: #4C89BB;
  font-weight: 600;
}
.hlCenter .hlMain .usSug .usSugg .usSug2 {
  position: relative;
}
</style>